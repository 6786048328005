import React from 'react';

import { Outlet } from 'react-router-dom';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { usePartner } from './api/usePartner';
import { Layout } from './components/Layout';
import { HomePartner } from './features/HomePartner';
import { RedirectToNewForm } from './features/PartnerSendLeadForm/components/RedirectToNewForm';
import { PartnerSendLeadForm } from './features/PartnerSendLeadForm/Loadable';

const Index: React.FC = () => {
  const { partner, isLoading } = usePartner();

  return !partner || isLoading ? (
    <Loader />
  ) : (
    <Layout logoUrl={partner.logoPath} logoTo={`/partner/${partner.token}`}>
      <Outlet />
    </Layout>
  );
};
export const Partner = { Index, HomePartner, PartnerSendLeadForm, RedirectToNewForm };
