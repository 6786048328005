import React from 'react';

import { Form } from 'react-final-form';

import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { required, requiredValidEmail } from '@travauxlib/shared/src/utils/form';

import { useEstimateur } from 'features/Estimateur/api/useEstimateur';
import { Estimation } from 'features/Estimateur/types';

import { useSubmitLead } from '../api/useSubmitLead';

type Props = {
  estimation: Estimation;
  handleClose: () => void;
};

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  emailCommercial: string;
  estimationDescription: string;
};

export const SendLeadModal: React.FC<Props> = ({ estimation, handleClose }) => {
  const { profile } = useEstimateur();
  const submitLead = useSubmitLead();

  return (
    <Modal isOpen title="Envoi du projet à hemea" handleClose={handleClose}>
      <Form<FormData>
        onSubmit={async values => {
          await submitLead({
            description: `Le partenaire ${values.firstName} ${values.lastName} (${values.emailCommercial}) a fait la description suivante :\n${values.estimationDescription}`,
            emailCommercial: values.emailCommercial,
            affiliateToken: profile!.affiliateToken,
            estimation: { ...estimation, email: values.email },
          });
          handleClose();
        }}
        initialValues={{
          firstName: profile?.firstName,
          lastName: profile?.lastName,
          emailCommercial: profile?.email,
          email: estimation.email,
        }}
      >
        {({ handleSubmit, submitting }) => (
          <ModalContent
            cancelAction={{
              label: 'Annuler',
              disabled: submitting,
              onClick: handleClose,
            }}
            validateAction={{
              label: 'Envoyer le projet à hemea',
              disabled: submitting,
              loading: submitting,
              type: 'submit',
            }}
            handleSubmit={handleSubmit}
          >
            <InputField
              id="email"
              name="email"
              className="mb-md"
              label="Email du client"
              validate={requiredValidEmail}
            />
            <InputField
              id="firstName"
              name="firstName"
              className="mb-md"
              label="Prénom du commercial"
              validate={required}
            />
            <InputField
              id="lastName"
              name="lastName"
              className="mb-md"
              label="Nom du commercial"
              validate={required}
            />
            <InputField
              id="email-commercial"
              name="emailCommercial"
              className="mb-md"
              label="Email du commercial"
              validate={requiredValidEmail}
            />
            <TextareaField
              id="estimationDescription"
              name="estimationDescription"
              className="mb-md"
              label="Description du projet"
              placeholder="Exemple : appartement de 50 m² qui nécessite la réfection de l'électricité, peintures et cuisine"
              validate={required}
            />
          </ModalContent>
        )}
      </Form>
    </Modal>
  );
};
