import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { useProject } from '../api/useProject';

export const RedirectSuiviChantier: React.FC = () => {
  const { uuid, suiviChantierUuid } = useParams();
  const navigate = useNavigate();
  const { isLoading, project } = useProject(uuid!);
  const targetChantierUuid = project?.chantiers.find(
    c => c.suiviToValidate?.cycleUUID === suiviChantierUuid,
  )?.uuid;

  React.useEffect(() => {
    if (!isLoading && uuid && targetChantierUuid && suiviChantierUuid) {
      navigate(`../chantiers/${targetChantierUuid}/suivi-chantier/${suiviChantierUuid}`, {
        replace: true,
      });
    }
  }, [isLoading, targetChantierUuid, suiviChantierUuid]);

  return <Loader />;
};
