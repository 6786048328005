import React from 'react';

type Props = {
  title: string;
  typeTravaux: string;
};

export const Title: React.FC<Props> = ({ title, typeTravaux }) => (
  <div className="my-sm">
    {typeTravaux !== 'N/A' && (
      <div className="text-neutral-600 text-b2 font-bold">{typeTravaux}</div>
    )}
    {title && <div className="text-neutral-800 font-bold text-h5">{title}</div>}
  </div>
);
