import React from 'react';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';

import { Project } from 'features/EspaceClient3/types';

import { MeetingsSection } from './MeetingsSection';
import { ProjectInfo } from './ProjectInfo';

type Props = {
  projet: Project;
};

export const ProjectCard: React.FC<Props> = ({ projet }) => (
  <Card className="shadow-sm rounded-md" bodyClassNames="!p-sm">
    {!!projet.etape && (
      <ProjectInfo
        projectStep={projet.etape}
        projectShortAddress={projet.address.formatted_address}
      />
    )}
    <div className="mt-xs">
      <MeetingsSection projectUuid={projet.uuid} />
    </div>
  </Card>
);
