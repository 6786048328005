import { create } from 'zustand';

type DiffType = 'deleted' | 'resolved';

type ActiveLigne = {
  ligneUuid: string;
  threadUuid?: string;
};

type RevisionState = {
  activeThread?: ActiveLigne & {
    isEditing: boolean;
  };
  isRevising: boolean;
  setIsRevising: (isRevising: boolean) => void;
  openThread: (ligne: ActiveLigne) => void;
  editThread: (ligne: ActiveLigne) => void;
  closeThread: () => void;
  showDiff: boolean;
  setShowDiff: (isShowDiff: boolean) => void;
  diffTypes: DiffType[];
  setDiffTypes: (diffTypes: DiffType[]) => void;
};

export const useRevisionStore = create<RevisionState>(set => ({
  comments: {},
  isRevising: false,
  setIsRevising: (isRevising: boolean) =>
    set(state => ({
      isRevising,
      showDiff: isRevising ? state.showDiff : false,
    })),
  showDiff: false,
  diffTypes: ['deleted'],
  setDiffTypes: (diffTypes: DiffType[]) =>
    set(() => ({
      diffTypes,
    })),
  setShowDiff: (showDiff: boolean) =>
    set(() => ({
      showDiff,
    })),
  openThread: (payload: ActiveLigne) =>
    set(() => ({
      activeThread: {
        ...payload,
        isEditing: false,
      },
    })),
  editThread: (payload: ActiveLigne) =>
    set(() => ({
      activeThread: {
        ...payload,
        isEditing: true,
      },
    })),
  closeThread: () =>
    set(() => ({
      activeThread: undefined,
    })),
}));
