import React from 'react';

import { useParams } from 'react-router-dom';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { NotFound } from 'features/NotFoundPage';
import { Devis } from 'types';

import { useDevisPro } from '../api/useDevisPro';

export const WithDevisPro = <P extends object>(
  Component: React.ComponentType<P & { devis: Devis }>,
): React.ComponentType<P> => {
  const Wrapper = (props: P): JSX.Element => {
    const { token } = useParams();
    const { isLoading, devis } = useDevisPro(token!);
    if (isLoading) {
      return <Loader />;
    }
    if (!devis) {
      return <NotFound />;
    }
    return <Component {...props} devis={devis} />;
  };
  return Wrapper;
};
