import React, { useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import SuccessCalendarPicto from '@travauxlib/shared/src/components/DesignSystem/assets/SuccessCalendarPicto.svg?react';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links/ButtonLink';
import { InlineLoader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/InlineLoader';

import OnboardingRdvQuery from 'assets/icons/onboarding_rdv_ask.svg?react';
import { CallUsButton } from 'components/CallUsButton';
import { StructureCard } from 'components/Card';

import { QuestionsReassurance } from './QuestionsReassurance';
import { ReassuranceProjectSave } from './ReassuranceProjectSave';

import { useLeadPublic } from '../api/useLeadPublic';
import { publicLeadAutoAssign } from '../api/usePublicAutoAssignLead';

export const ProjectSaved: React.FC = () => {
  const [hasTakenRdv, setHasTakenRdv] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const appRedirect = searchParams.get('appRedirect');
  const leadToken = searchParams.get('token');

  const { isLoading, lead, calendlyGlobal, isError } = useLeadPublic(leadToken);

  const redirectUrl = appRedirect ? `/${decodeURIComponent(appRedirect)}` : '/';

  React.useEffect(() => {
    if (!isLoading && isError) {
      navigate(redirectUrl);
    }
  }, [isLoading, isError]);

  return (
    <>
      <h4 className="text-h4 font-bold inline-flex text-neutral-800 mb-xs">
        {hasTakenRdv ? 'Rendez-vous confirmé' : 'Votre projet a bien été enregistré'}
      </h4>
      <div className="text-b1 text-neutral-800 mb-lg">
        {hasTakenRdv
          ? 'Votre rendez-vous a bien été pris en compte'
          : 'Nos équipes vont examiner attentivement votre demande'}
      </div>
      {hasTakenRdv ? (
        <StructureCard className="-mx-md sm-desktop:mx-0 mb-md">
          <div className="mb-lg mx-auto w-[7.5rem] h-[7.5rem] rounded-full">
            <SuccessCalendarPicto />
          </div>
          <div className="text-b2 text-neutral-600 mb-xl text-left">
            Une fois sur votre espace personnel, vous aurez la possibilité de charger tous les
            éléments qui nous permettront de préparer au mieux l’appel (plans, devis déjà réalisés,
            photos...)
          </div>
          <ButtonLink to={redirectUrl} fullwidth>
            Accéder à mon espace personnel
          </ButtonLink>
        </StructureCard>
      ) : (
        <>
          {isLoading && <InlineLoader />}
          {!isLoading && lead && calendlyGlobal && (
            <>
              <StructureCard className="-mx-md sm-desktop:mx-0 mb-md">
                <div className="flex items-center justify-center">
                  <OnboardingRdvQuery />
                </div>
                <div className="text-b2 text-neutral-600 mb-xl mt-md">
                  Pour continuer à planifier la suite de votre chantier, prenez rendez-vous avec
                  l'un de nos experts hemea, disponible du lundi au vendredi, de 9h00 à 19h00, par
                  téléphone ou en visio.
                </div>
                <CallUsButton
                  calendlyUrl={calendlyGlobal}
                  accountOrLead={lead}
                  wording="Prendre rendez-vous"
                  calendlySource="client-end-of-onboarding"
                  onRdvTaken={() => {
                    setHasTakenRdv(true);
                  }}
                />
              </StructureCard>
              <ReassuranceProjectSave />
            </>
          )}
          <StructureCard className="-mx-md sm-desktop:mx-0 mb-md">
            <div className="text-b1 font-bold text-neutral-800 mb-md text-left">
              Votre espace personnel
            </div>
            <div className="text-b2 text-neutral-600 mb-xl">
              Vous aurez la possibilité de charger tous les éléments qui nous permettront de
              préparer au mieux l’appel (plans, devis déjà réalisés, photos...)
            </div>
            <div className="text-center">
              <ButtonLink
                to={redirectUrl}
                onClick={() => {
                  if (leadToken) {
                    publicLeadAutoAssign(leadToken);
                  }
                }}
                variant="secondary"
                fullwidth
              >
                Accéder à mon espace personnel
              </ButtonLink>
            </div>
          </StructureCard>
        </>
      )}
      <QuestionsReassurance />
    </>
  );
};
