import React from 'react';

import classNames from 'classnames';

import {
  InlineLoader,
  LoaderProps,
} from '@travauxlib/shared/src/components/DesignSystem/components/Loader/InlineLoader';

type Props = {
  size?: LoaderProps['size'];
  isFullHeightScreen?: boolean;
  testId?: string;
};

export const Loader: React.FC<Props> = ({ size = 'lg', isFullHeightScreen, testId }) => (
  <div
    data-testid={testId}
    className={classNames(
      'w-full flex items-center justify-center grow',
      isFullHeightScreen ? 'h-screen' : 'h-full',
    )}
  >
    <InlineLoader size={size} />
  </div>
);
