import React from 'react';

import { ErrorBoundary } from '@sentry/react';
import classNames from 'classnames';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { LayoutGrid } from '@travauxlib/shared/src/components/DesignSystem/components/Layout';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { useAccount } from 'api/useAccount';

import { ProjectHeader } from './Header/ProjectHeader';
import { SideBar } from './SideBar';
import { TapBar } from './TapBar';

import { useBadges } from '../api/useBadges';
import { OupsPage } from '../features/Index/components/OupsPage';
import { routesWithoutHeaderTapBar, routesWithoutLayout, routesWithoutTapBar } from '../utils';

export const Layout: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { account, logout, isLoading } = useAccount();
  const pathname = useLocation().pathname;
  const { badges, isLoading: isBadgesLoading } = useBadges(uuid!);

  if (isLoading || isBadgesLoading) {
    return <Loader />;
  }

  const displayTapBar = ![...routesWithoutTapBar, ...routesWithoutHeaderTapBar].some(route =>
    pathname.includes(route),
  );
  const displayProjectHeaderAndTapBar = !routesWithoutHeaderTapBar.some(route =>
    pathname.includes(route),
  );
  const disableGrid = routesWithoutLayout.some(r => pathname.includes(r));

  return disableGrid ? (
    <Outlet />
  ) : (
    <div className="min-h-[100dvh] bg-neutral-100 flex flex-col">
      {displayProjectHeaderAndTapBar && (
        <ProjectHeader account={account!} logout={logout} projectUuid={uuid!} />
      )}
      <div
        className={classNames('min-h-full bg-neutral-100 flex grow', {
          'pt-3xl': !displayProjectHeaderAndTapBar,
        })}
      >
        <LayoutGrid ec3 className={classNames('grow')}>
          <>{displayProjectHeaderAndTapBar && <SideBar projectUuid={uuid!} badges={badges} />}</>
          {/* Spacing on bottom of page is from eng design, the bg :) */}
          <div className="col-span-full mt-0 sm-desktop:mt-md mb-3xl sm-desktop:col-start-4 sm-desktop:col-span-full md-desktop:col-start-4 md-desktop:col-span-6 md-desktop:mx-0">
            <ErrorBoundary fallback={<OupsPage />}>
              <Outlet />
            </ErrorBoundary>
          </div>
        </LayoutGrid>
      </div>
      {displayTapBar && <TapBar projectUuid={uuid!} badges={badges} />}
    </div>
  );
};
