import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECTS_KEY } from 'features/EspaceClient3/api/useProjects';

const handleValidateAppelDePaiement = async (payload: {
  chantierSlug: string;
  appelDePaiementUuid: string;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.apiURL}/client/chantiers/${payload.chantierSlug}/appels-de-paiement/${payload.appelDePaiementUuid}/validate`;
  return request(requestURL, { method: 'POST' });
};

export const useValidateAppelDePaiement = (
  projectUuid: string,
): {
  isLoading: boolean;
  isSuccess: boolean;
  validateAppelDePaiement: typeof handleValidateAppelDePaiement;
} => {
  const queryClient = useQueryClient();
  const { isPending, isSuccess, mutateAsync } = useMutation({
    mutationFn: handleValidateAppelDePaiement,
    retry: 0,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PROJECTS_KEY, projectUuid] });
      toast.success('Les fonds ont été débloqués avec succès.');
    },
  });

  return { isLoading: isPending, isSuccess, validateAppelDePaiement: mutateAsync };
};
