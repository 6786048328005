import React from 'react';

import { useParams } from 'react-router-dom';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { NotFound } from 'features/NotFoundPage';
import { SuiviChantierClient } from 'types';

import { useSuiviChantier } from '../api/useSuiviChantier';

export const WithSuiviChantier = <P extends object>(
  Component: React.ComponentType<P & { suiviChantier: SuiviChantierClient }>,
): React.ComponentType<P> => {
  const Wrapper = (props: P): JSX.Element => {
    const { token } = useParams();
    const { isLoading, suiviChantier } = useSuiviChantier(token!);
    if (isLoading) {
      return <Loader />;
    }
    if (!suiviChantier) {
      return <NotFound />;
    }
    return <Component {...props} suiviChantier={suiviChantier} />;
  };
  return Wrapper;
};
