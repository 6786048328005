import React from 'react';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { PlacesAutocompleteField } from '@travauxlib/shared/src/components/PlacesAutocomplete/Field';
import {
  required,
  requiredValidEmail,
  requiredValidGoogleMapAddress,
  requiredValidPhoneNumber,
} from '@travauxlib/shared/src/utils/form';

export const PartnerFields: React.FC<{ initialGoogleMapsInputValue?: string }> = ({
  initialGoogleMapsInputValue,
}) => (
  <>
    <div className="flex flex-wrap">
      <div className="w-full px-md tablet:max-w-1/2">
        <InputField
          label="Prénom *"
          className="mb-md"
          autoFocus
          id="firstName"
          name="firstName"
          validate={required}
        />
      </div>
      <div className="w-full px-md tablet:max-w-1/2">
        <InputField
          label="Nom *"
          className="mb-md"
          id="lastName"
          name="lastName"
          validate={required}
        />
      </div>
    </div>
    <PlacesAutocompleteField
      label="Adresse ou code postal *"
      id="estimation-address"
      className="mb-md px-md"
      name="googleMapsAddress"
      validate={requiredValidGoogleMapAddress('postal_code')}
      initialInputValue={initialGoogleMapsInputValue}
      googleApiKey={APP_CONFIG.googleApiKey}
    />
    <div className="flex flex-wrap">
      <div className="w-full px-md tablet:max-w-1/2">
        <InputField
          id="email"
          label="Email *"
          className="mb-md"
          name="email"
          validate={requiredValidEmail}
        />
      </div>
      <div className="w-full px-md tablet:max-w-1/2">
        <InputField
          id="phone-number"
          label="Numéro de téléphone *"
          className="mb-md"
          name="phoneNumber"
          validate={requiredValidPhoneNumber}
        />
      </div>
    </div>
  </>
);

type Props = {
  handleCancel?: () => void;
  valid: boolean;
  initialGoogleMapsInputValue?: string;
};

export const PartnerProjectInfo: React.FC<Props> = ({
  valid,
  handleCancel,
  initialGoogleMapsInputValue,
}) => (
  <div>
    {!handleCancel && <h3 className="font-bold mb-md">Nouveau projet</h3>}
    <div className="mb-md">
      <PartnerFields initialGoogleMapsInputValue={initialGoogleMapsInputValue} />
    </div>
    <div className="mt-xl">
      {handleCancel && (
        <Button className="mr-sm" variant="secondary" onClick={handleCancel}>
          Annuler
        </Button>
      )}
      <Button type="submit" disabled={!valid}>
        Valider
      </Button>
    </div>
  </div>
);
